/* eslint-disable */
import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/id'
moment.locale('id');
import { id } from 'date-fns/locale'; // this is localization for Indonesian Language......
import "../Styles/SelectStation.css";
import { Header, Footer, SelectStationDropDown, Loader } from "../index";

import { Button, Checkbox, FormControlLabel, MenuItem, Paper, Select, styled, TextField, Typography } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { makeStyles } from '@mui/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { getSchedule, getStation } from '../../api/ApiUtils';
import { PopUpModal } from '../CommonComponents/PopUp/PopUpModal';
import CheckBoxOutlineBlankRoundedIcon from '@mui/icons-material/CheckBoxOutlineBlankRounded';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';

const StationSelection = () => {
    const classes = useStyle();
    const header = "Cari Jadwal Kereta";
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    // STATE'S TO HANDLE THE SLIDERS TO SELECT THE STATION NAMES
    const [startStation, setStartStation] = useState("");
    const [openStartStationDropDown, setOpenStartStationDropDown] = useState(false);

    const [stopStation, setStopStation] = useState("");
    const [openStopStationDropDown, setOpenStopStationDropDown] = useState(false);

    const [errorPopUp, showErrorPopUp] = useState({ showPopUp: false });

    const [stationList, setStation] = useState([]);

    // SET THE DEPARTURE DATE
    const [departureDate, setDepartureDate] = useState(new Date());

    // SET CHECKBOX 
    const [termsandconditions, setTermsandconditions] = useState(false);

    // STATE TO SELECT PASSENGERS
    const [adultPassengers, setAdultPassengers] = useState(1);
    const [childPassengers, setChildPassengers] = useState(0);
    const [childError, setChildError] = useState(false);
    // TO CHECK IF NO OF CHILD PASSENGERS !> ADULT
    useEffect(() => {

        if (childPassengers <= adultPassengers) {
            setChildError(false)
        }
        if (childPassengers > adultPassengers) {
            setChildError(true);
        }

    }, [childPassengers, adultPassengers]);

    const [error, setError] = useState(false);
    const handleSubmit = async () => {
        if (!termsandconditions) return;

        const verifiedData = (startStation && stopStation) && !childError && termsandconditions;
        if (verifiedData) {
            const p = Promise.resolve(JSON.parse(localStorage.getItem("KAI")));
            p.then(res => {
                let KAI = res == null ? {} : res;
                KAI.stationDetails = {
                    sourceStation: startStation,
                    destnationStation: stopStation,
                    date: departureDate,
                    adultPassengers: adultPassengers,
                    childPassengers: childPassengers
                }
                localStorage.setItem("KAI", JSON.stringify(KAI)); // store the station details in localStorage
            })
            let req = {
                "originStationCode": startStation.stationcode,
                "destinationStationCode": stopStation.stationcode,
                "departureDate": moment(departureDate).format("YYYY-MM-DD"),
                "numberOfAdult": adultPassengers,
                "numberOfChildren": childPassengers
            }
            setLoading(true);
            let res = await getSchedule(req);
            if (res.success) {
                p.then(() => navigate("/stationpricecard"));
                setLoading(false)
            }
            else {
                showErrorPopUp({ ...errorPopUp, showPopUp: true, message: res.message });
                setLoading(false);
            }
        }
        else {
            setLoading(false);
            setError(true)
        }
    }

    // FOR SUBMIT TO ACTIVATE AND DEACTIVATE 
    useEffect(() => {
        if (error) {
            if (!childError && startStation && stopStation) {
                setError(false);
            }
        }
    }, [childError, startStation, stopStation])

    useEffect(async () => {
        setLoading(true);
        localStorage.removeItem("KAI");
        let KAI = {};
        localStorage.setItem("KAI", JSON.stringify(KAI));
        const response = await getStation();
        if (response.success) {
            setStation(response.data);
            setLoading(false);
        } else {
            showErrorPopUp({ ...errorPopUp, showPopUp: true, message: response.message });
            setLoading(false);
        }

    }, []);

    const handlePopUpClose = () => {
        showErrorPopUp({ ...errorPopUp, showPopUp: false });
    }

    const startStationErrorClass = (error && !startStation) ? "selectStationDropdown-error" : "selectStationDropdown"
    const stopStationErrorClass = (error && !stopStation) ? "selectStationDropdown-error" : "selectStationDropdown" 
    const startStationNameCode = startStation.stationname ? startStation.stationname + ' (' + startStation.stationcode + ')' : '';
    const stopStationNameCode = stopStation.stationname ? stopStation.stationname + ' (' + stopStation.stationcode + ')' : '';
    const submitButtonCheck = error || childError || !termsandconditions;

    return (
        <div id="StationSelection" className={"StationSelection"}>
            <Loader loading={loading} />
            <PopUpModal open={errorPopUp.showPopUp} error={true} message={errorPopUp.message} handlePopUpClose={handlePopUpClose} />

            <div style={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
                {/* HEADER */}
                <Header header={header} back={""} step={'1'} />

                {/* SCHEDULE STATION CONTAINER */}
                <div id="StationSelectionForm" className={"StationSelectionForm"}>

                    {/* Select Dropdown for Station Names Date and no.of Passengers */}
                    <div>
                        <InputLabel children={"Stasiun Asal"} id="StasiunAsal-label" />
                        <div onClick={() => setOpenStartStationDropDown(!openStartStationDropDown)} className={startStationErrorClass} id="StasiunAsal-value" >
                            <StationName children={startStationNameCode} id="StasiunAsal-name" />
                            <KeyboardArrowDownIcon className={"iconArrowInSelectInput"} />
                        </div>
                        {(error && !startStation) &&
                            <ErrorSpan >
                                Pilih stasiun asal terlebih dahulu
                            </ErrorSpan>
                        }

                        <InputLabel children={"Stasiun Tujuan"} id="StasiunTujuan-label" />
                        <div onClick={() => setOpenStopStationDropDown(!openStopStationDropDown)} className={stopStationErrorClass} id="StasiunTujuan-value" >
                            <StationName children={stopStationNameCode} id="StasiunTujuan-name" />
                            <KeyboardArrowDownIcon className={"iconArrowInSelectInput"} />
                        </div>
                        {(error && !stopStation.stationname) &&
                            <ErrorSpan >
                                Pilih stasiun tujuan terlebih dahulu
                            </ErrorSpan>
                        }

                        {/* Select Date */}
                        <DatePicker classes={classes} departureDate={departureDate} setDepartureDate={setDepartureDate} />

                        {/* Adult Passangers */}
                        <SetAdultPassengers setAdultPassengers={setAdultPassengers} adultPassengers={adultPassengers} />
                        {/* Infant Passangers */}
                        <SetChildPassengers setChildPassengers={setChildPassengers} childError={childError} childPassengers={childPassengers} />
                    </div>

                    {/* Terms and Conditions */}
                    <TermsandConditions classes={classes} setTermsandconditions={setTermsandconditions} />

                    {/* SUBMIT BUTTON */}
                    <Button
                        children={"Cari Kereta Api"}
                        fullWidth
                        variant={"contained"}
                        onClick={() => handleSubmit()}
                        id="CariKeretaApi"
                        className={submitButtonCheck ? `themeButton disableSubmitButton` : `themeButton themeButtonContained`}
                    />
                </div>

                {/* FOOTER */}
                <Footer />
            </div>
            <SelectStationDropDown
                list={stationList}
                slideOpen={openStartStationDropDown}
                setOpenStationDropDown={setOpenStartStationDropDown}
                setStationName={setStartStation}
                title={"Stasiun Awal"}
            />
            <SelectStationDropDown
                list={stationList}
                slideOpen={openStopStationDropDown}
                setOpenStationDropDown={setOpenStopStationDropDown}
                setStationName={setStopStation}
                title={"Stasiun Tujuan"}
            />
        </div>
    );
};

export default StationSelection;

const DatePicker = ({ classes, departureDate, setDepartureDate }) => {
    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ flex: "1" }}> {/* Train Ticiket Departure Date */}
                <InputLabel children={"Tanggal Keberangkatan"} id="TanggalKeberangkatan-label" />
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={id} >
                    <MobileDatePicker InputProps={{ style: { borderRadius: "8px" } }} disablePast
                        minDate={moment().toDate()}
                        /* selecting exact 90 days */
                        maxDate={moment().add(89, 'days').toDate()} 
                        DialogProps={{ className: classes.selectDepartureDate }}
                        value={departureDate} cancelText={""}
                        onChange={(date) => setDepartureDate(date)}
                        renderInput={(params) => {
                            params.inputProps.value = moment(params.inputProps.value, "DD/MM/YYYY").format("ddd, DD MMM YYYY");
                            return <TextField fullWidth className={classes.departureDateTextField} {...params} />
                        }}
                    />
                </LocalizationProvider>
            </div>
        </div>
    )
}
const SelectIcon = () => {
    return (
        <KeyboardArrowDownIcon className={"iconArrowInSelectInput"} />
    )
}

const SetAdultPassengers = ({ setAdultPassengers, adultPassengers }) => {
    return (
        <Fragment>
            <InputLabel children={"Penumpang Dewasa (>=3th)"} id="PenumpangDewasa-label" />
            <StyledSelect value={adultPassengers} onChange={(e) => setAdultPassengers(e.target.value)} IconComponent={SelectIcon} fullWidth id="PenumpangDewasa-value">
                <MenuItem children={"1 Orang"} value={1} />
                <MenuItem children={"2 Orang"} value={2} />
                <MenuItem children={"3 Orang"} value={3} />
                <MenuItem children={"4 Orang"} value={4} />
            </StyledSelect>
        </Fragment>
    )
}
const SetChildPassengers = ({ setChildPassengers, childError, childPassengers }) => {
    return (
        <Fragment>
            <InputLabel children={"Penumpang Bayi (<3th)"} id="PenumpangBayi-label" />
            <StyledSelect error={childError} value={childPassengers} onChange={(e) => setChildPassengers(e.target.value)} IconComponent={SelectIcon} fullWidth id="PenumpangBayi-value">
                <MenuItem children={"0 Orang"} value={0} />
                <MenuItem children={"1 Orang"} value={1} />
                <MenuItem children={"2 Orang"} value={2} />
                <MenuItem children={"3 Orang"} value={3} />
                <MenuItem children={"4 Orang"} value={4} />
            </StyledSelect>
            {childError && <ErrorSpan>
                Penumpang bayi dilarang melebihi jumlah penumpang dewasa
            </ErrorSpan>}
        </Fragment>
    )
}

const TermsandConditions = ({ setTermsandconditions }) => {
    return (
        <div style={{ margin: '16px auto' }}>
            {/* TERMS & CONDITIONS */}
            <div style={{ margin: "16px 0" }}>
                <Typography className={'terms'}>
                    Produk atau jasa ini disediakan oleh Kereta Api Indonesia dan sepenuhnya menjadi tanggung jawab Kereta Api Indonesia.
                </Typography>
            </div>

            {/* TREMS & CONDITIONS CHECKBOX */}
            <Paper className={`dropShadow`} style={{ padding: '16px 0 16px 16px' }}>
                <FormControlLabel
                    label={
                        <Typography className={'termsCheckbox'}>
                            Saya telah membaca dan setuju terhadap{" "}
                            <span>
                                <Anchor
                                    sx={{color:'#0047A9'}}
                                    target="_self"
                                    href='https://apps.kereta-api.co.id/termcondition/intercitytrains/ID'
                                >
                                Syarat dan Ketentuan pembelian tiket
                                </Anchor>
                            </span>
                            {" "}
                            dan
                            {" "}
                            <span>
                                <Anchor
                                    target="_self"
                                    sx={{color:'#0047A9'}}
                                    rel="noreferrer"
                                    href="https://www.ayoconnect.com/privacy-policy"
                                >
                                Kebijakan Privasi
                                </Anchor>
                            </span>
                        </Typography>}
                    control={
                        <Checkbox 
                            icon={<CheckBoxOutlineBlankRoundedIcon />}
                            checkedIcon={<CheckBoxRoundedIcon htmlColor={'#0047A9'}/>}
                        />
                    }
                    onChange={(e) => setTermsandconditions(e.target.checked)}
                />

            </Paper>


        </div>
    )
}
const useStyle = makeStyles({
    selectDepartureDate: {
        // Hide Cancel Button
        '& .MuiDialogActions-root>:first-of-type': {
            display: 'none'
        },
        // giv a line after the date displayed at top in Date Picker
        '& .PrivatePickersToolbar-root': {
            borderBottom: "1px solid #DDD !important"
        },
        // removed the edit button which changed the view of the date picker
        '& .MuiPickersToolbar-penIconButton': {
            display: "none",
        },
        // change color and background of the CANCEL and OK button
        '& .MuiButton-root': {
            background: "#0047A9",
            color: "#FFF",
            '&:hover': {
                background: "#0047A9",
            }
        },
        // background color applied to date when selected and also when seledted and hover on it
        '& .Mui-selected': {
            backgroundColor: "#0047A9 !important",
            '&:hover': {
                backgroundColor: "#0047A9bf !important"
            }
        },
        '& .MuiPickersDay-today': {
            borderColor: '#0047A9'
        }
    },
    departureDateTextField: {
        // style APPLIED to TEXTFIELD 
        '& .MuiOutlinedInput-input': {
            padding: "10px 14px !important",
            fontSize: "14px !important",
        }
    }
}) 
const StationName = styled(Typography)({
    fontWeight: '500',
    fontSize: '14px',
    color: '#1A1A1A',
})
const ErrorSpan = styled('span')({ 
    color: "#d32f2f", 
    fontSize: "12px", 
    fontWeight: "500" 
})
const StyledSelect = styled(Select)({
    height: '40px',
    borderRadius: '8px',
    fontSize: '14px'    
})
const Anchor = styled('a')({ 
    color: '#333', 
    fontWeight: 700, 
    textDecoration: 'none' 
})
const InputLabel = styled(Typography)({
    fontWeight: 'bold',
    fontSize: '12px',
    color: '#515151',
    marginBottom: '8px',
    marginTop: '24px',
})